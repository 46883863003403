export default {
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
  "Welcome to the Matress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا بك في موقع ماتريس"])},
  "Fill in the data and then enjoy the best types of mattresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتعبئة البيانات ثم تمتع بأفضل أنواع المراتب"])},
  "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "Forget Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور"])},
  "Create New Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب جديد"])},
  "Create Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإسم"])},
  "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم بالكامل"])},
  "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
  "Invitation Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود الدعوة"])},
  "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الحالية"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكيد كلمة المرور"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخريطة"])},
  "manual_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنطقة يديويا"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء الإنتظار ..."])},
  "password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور غير متطابقة"])},
  "old_password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الحالية غير صحيحة"])},
  "password_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تغيير كلمة المرور بنجاح"])},
  "required_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل مطلوب"])},
  "login_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ في اسم المستخدم أو كلمة المرور"])},
  "signup_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني أو رقم الهاتف الذي أدخلته مستخدم في حساب آخر"])},
  "follow_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعونا على"])},
  "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البراند"])},
  "error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ الرجاء المحاولة مرة أخرى"])},
  "success_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الحفظ بنجاح."])},
  "alert_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل أنت متأكد أنك تريد حذف هذا العنصر؟"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
  "Entre Otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل الكود"])},
  "Send code again !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة إرسال الكود!"])},
  "otp_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال الكود الذي تم إرساله إلى "])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
  "code_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكود الذي أدخلته غير صحيح"])},
  "code_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال الكود بنجاح, الرجاء التحقق من بريدك الالكتروني"])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تعيين كلمة المرور"])},
  "Subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموضوع"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الخصم"])},
  "rate_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم المنتجات"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات"])},
  "direct_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستفسار السريع عن طريق واتساب"])},
  "no_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد منتجات"])},
  "nav": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
    "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعدادات"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
    "change-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغير اللغة"])},
    "all-brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل البراند"])},
    "favourite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفضلة"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلة التسوق"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدعم والمساعدة"])},
    "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسئلة الشائعة"])},
    "terms-of-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والأحكام"])}
  },
  "home": {
    "products-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توصيل المنتجات"])},
    "delivery-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توصيل خلال 24 ساعة"])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجودة"])},
    "quality-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أفضل العلامات التجارية"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المساعدة"])},
    "help-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمة ومتابعة العملاء"])},
    "payment-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول الدفع"])},
    "payment-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كي نت , فيزا , ماستر , كاش"])},
    "most-selling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأكثر شراء"])},
    "month-offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عروض هذا الشهر"])},
    "all-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل المنتجات"])},
    "get-the-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على تطبيق الهاتف المحمول"])},
    "get-app-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حمل التطبيق واستمتع بالعديد من المزايا الكثيرة"])},
    "get-app-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["والمزيد من العروض الرائعة."])}
  },
  "settings": {
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلوماتي"])},
    "general-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات شخصية"])},
    "my-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلباتي"])},
    "previous-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلباتي السابقة"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عناويني"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
    "my-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النقاط"])},
    "points-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد النقاط المحصلة لديك"])},
    "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقطة"])},
    "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
    "edit-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الملف الشخصي"])},
    "add-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة موقع جديد"])}
  },
  "order": {
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطلب"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الطلب"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر الإجمالي"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتج"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكمية"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التكلفة"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوصيل"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضريبة"])},
    "promoCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برموكود"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد الطلب"])},
    "order-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الطلب"])},
    "product-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة المنتج"])},
    "product-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المنتج"])},
    "shipping-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الشحن"])},
    "invoice-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الفاتورة"])},
    "receipt-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلب قيد المراجعة"])},
    "order-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد التوصيل"])},
    "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التوصيل"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجم"])},
    "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلب ملغي"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلب مرفوض"])},
    "order_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلب مقبول"])}
  },
  "product": {
    "add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة إلى السلة"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاركة"])},
    "similar-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتجات مشابهة"])},
    "added-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الإضافة إلى السلة"])},
    "cart-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلة التسوق فارغة"])}
  },
  "contact": {
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يسعدنا تواصلك معنا"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسالة "])}
  }
}