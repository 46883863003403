<template>
    <AuthContainer>
        <template v-slot:content>
            <div class="login-l registerForm">
                <div class="h1">{{ $t('Create Account') }}</div>
                <form @submit.prevent="createUser">
                    <div v-if="error_msg" class="alert alert-danger font-weight-bold error_msg">
                        {{error_msg}}
                    </div>
                    <div class="form-group" :class="{ 'form-group--error': v$.form.name.$error }">
                        <label for="name">{{ $t('Username') }}</label>
                        <input type="text" id="name" v-model="form.name" class="form-control"
                               placeholder="">
                        <div class="text-danger mt-1 font-weight-bold error_msg"
                             v-if="v$.form.name.$error && form.name.length === 0">
                            {{ v$.form.name.$errors[0].$message }}
                        </div>
                    </div>

                    <div class="form-group"
                         :class="{ 'form-group--error': v$.form.email.$error && form.email.length === 0}">
                        <label for="email">{{ $t('Email Address') }}</label>
                        <input type="email" id="email" class="form-control"
                               placeholder="" v-model="v$.form.email.$model">
                        <div class="text-danger mt-1 font-weight-bold error_msg"
                             v-if="v$.form.email.$error && form.email.length === 0">
                            {{ v$.form.email.$errors[0].$message }}
                        </div>
                    </div>

                    <div class="form-group" :class="{ 'form-group--error': v$.form.mobile.$error }">
                        <label for="phone">{{ $t('Phone') }}</label>
                        <input type="text" id="phone" v-model="v$.form.mobile.$model" class="form-control"
                               placeholder="">
                        <div class="text-danger mt-1 font-weight-bold error_msg" v-if="v$.form.mobile.$error">
                            {{ v$.form.mobile.$errors[0].$message }}
                        </div>
                    </div>

                    <div class="form-group">
                        <label>{{ $t('Country') }}</label>
                        <select v-model="form.country_id" class="form-control">
                            <option v-for="country in countries" :key="country.id" :value="country.id">
                                {{ country.name }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label>{{ $t('city') }}</label>
                        <select v-model="form.city_id" class="form-control">
                            <option v-for="city in cities" :key="city.id" :value="city.id">
                                {{ city.name }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="ref_affiltiate_code">{{ $t('Invitation Code') }}</label>
                        <input type="text" class="form-control" id="ref_affiltiate_code" v-model="form.ref_affiltiate_code"
                               placeholder="">
                    </div>

                    <div class="form-group mb-2" :class="{ 'form-group--error': v$.form.password.$error }">
                        <label for="password">{{ $t('Password') }}</label>
                        <input :type="passwordInputType" class="form-control" id="password"
                             autocomplete="on"  v-model="form.password"
                               placeholder="********">
                        <i @click="showPassword=!showPassword" v-show="form.password.length>0" class="toggle-password fa fa-fw fa-eye"
                           v-if="showPassword"></i>
                        <i @click="showPassword=!showPassword" v-show="form.password.length>0" class="toggle-password fa fa-fw fa-eye-slash" v-else></i>

                        <div class="text-danger mt-1 font-weight-bold error_msg" v-if="v$.form.password.$error">
                            {{ v$.form.password.$errors[0].$message }}
                        </div>
                    </div>

                    <button type="submit" class="btn btn-primary mt-4 align-self-center">
                        <span v-if="!loading"> {{ $t('Create Account') }} </span>
                        <span class="loading" v-else>{{ $t('loading') }}</span>
                    </button>

                    <div class="creat-link1">
                        <router-link to="login" class="text-dark">{{ $t('Login') }}</router-link>
                    </div>
                </form>
            </div>

        </template>


    </AuthContainer>
</template>

<script>
import AuthContainer from '@/containers/AuthContainer.vue';
import store from '../store';
import {useToast} from "vue-toastification";
import useValidate from '@vuelidate/core';
import {required, helpers, email, minLength} from '@vuelidate/validators';
import {getCountries, register} from '@/services/auth';
import {mapGetters} from "vuex";


export default {
    name: "Signup",
    components: {
        AuthContainer,
    },
    data: () => {
        return {
            v$: useValidate(),
            toast:useToast(),
            cities: [],
            form: {
                name: "",
                email: "",
                ref_affiltiate_code: "",
                mobile: "",
                country_id: "",
                city_id: "1",
                password: "",
            },
            showPassword: false,
            loading: false,
            hasError: false,
            errorMessage: '',
            error_msg: "",
        }
    },
    watch:{
        'form.country_id' : function() {
            let userCountry = this.countries.find(country=>country.id === this.form.country_id);
            this.cities = userCountry.Cities;
        }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    validations() {

        return {
            form: {
                name: {
                    required :helpers.withMessage(this.$t('required_msg'), required),
                },
                email: {
                    required :helpers.withMessage(this.$t('required_msg'), required),
                    email
                },
                mobile: {
                    required :helpers.withMessage(this.$t('required_msg'), required),
                },
                password: {
                    required :helpers.withMessage(this.$t('required_msg'), required),
                    min: minLength(6)
                },
            },
        }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    mounted: function () {
       this.form.country_id = store.getters.countries[0]?.id
    },
    computed: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        passwordInputType: function () {
            return this.showPassword ? "text" : "password";
        },
        ...mapGetters({
            countries: "countries",
        }),
    },
    methods: {

        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

        createUser() {
            this.loading = true;
            this.v$.$validate() // checks all inputs
            if (this.v$.$error) { // if ANY fail validation
                this.hasError = true
                this.loading = false;
                return false
            }
            try {
                register(this.form).then((res) => {
                    this.loading = false;
                    this.hasError = false;
                    if(res.data.status.status === 'fail') {
                        this.toast.error(this.$t('signup_error'),{timeout: 4000,  closeButton: false,})
                    }else {
                        store.dispatch("fetchUser", res.data.User);
                        let token = res.data.User.access_token;
                        localStorage.setItem('access_token',token)
                        this.$router.push('/')
                    }
                }).catch(e => {
                    console.log(e)
                })

            } catch (error) {
                this.loading = false;
                console.error(error)
            }

        }
    }


}
</script>

<style>
.registerForm .form-group select.form-control {
    color: #706f6f;
}

option {
    font-size: 1.25rem;
    text-transform: capitalize;
}

.error_msg {
    font-size: 13px;
}
</style>