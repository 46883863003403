
import AuthContainer from '@/containers/AuthContainer.vue';
import {checkCode, forgetPassword} from "@/services/auth";
import {useToast} from "vue-toastification";
import {flip} from "@popperjs/core";
export default {
    name: "ForgetPasswordOTP",
    components: {
        AuthContainer
    },
    data: () => {
        return {
            toast: useToast(),
            otp: "",
            email: '',
            loader: false,
            hasError: false,
            errorMessage: '',
            error_msg: "",
        }
    },
    mounted() {
        if (this.$route.params.email) {
            this.email = this.$route.params.email
        }else {
            this.$router.push('/login')
        }
    },

  methods: {
    processAction: function (): void {
        let data = {
            email: this.email,
            code: this.otp
        }
        this.loader = true
     checkCode(data).then(res=> {
        this.loader = false
         if(res.data.status.status === 'fail') {
             this.toast.error(this.$t('code_error'), {timeout: 4000, closeButton: false,})
         }else {
             this.$router.push({name: 'reset_password', params:{code:this.otp, email:this.email}})
         }

     }).catch(e=> {
         this.loader = false
         console.log(e)
     })

    },
    sendCodeAgain: function (): void {

        let data = {
            email : this.email,
            type: 1
        }
        forgetPassword(data).then(res=> {
            if(res.data.status.status === 'fail') {
                this.toast.error('Something went wrong, try again!', {timeout: 4000, closeButton: false,})

            }else {
                this.toast.success(this.$t('code_sent'), {timeout: 4000, closeButton: false,})

            }
        })
    }
  }


}
