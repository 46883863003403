import request from '../helper/request'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function register(data) {
    console.log(data)
    return request({
        url: `api/auth/signup`,
        method: 'post',
        data
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function login(data) {
    return request({
        url: `api/auth/login`,
        method: 'post',
        data
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function forgetPassword(data) {
    return request({
        url: `/api/auth/forget_password`,
        method: 'post',
        data
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function checkCode(data) {
    return request({
        url: '/api/auth/check_reset_code',
        method: 'post',
        data
    })
}


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function resetPassword(data) {
    return request({
        url: '/api/auth/reset_password',
        method: 'post',
        data
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function changePassword(data) {
    return request({
        url: `api/auth/change_password`,
        method: 'post',
        data
    })
}


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function updateProfile(data) {
    return request({
        url: `api/auth/update`,
        method: 'post',
        headers: {'Content-Type': 'multipart/form-data' },
        data
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getCountries() {
    return request({
        url: 'api/install',
        method: 'get'
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function contact(data) {
    return request({
        url: '/api/tickets/store',
        method: 'post',
        data
    })
}


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function socialLogin(data) {
    return request(
        {
            url: 'api/auth/social_login',
            method: 'post',
            data
        }
    )

}


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function logOut() {
    return request({
        url: `api/auth/logout`,
        method: 'post',
    })
}
