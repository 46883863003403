import request from "@/helper/request";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function home(){
    return request({
        url:'/api/home',
        method: 'get'
    })
}


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getStoreSettings(){
    return request({
        url:'api/install',
        method:'get',
        headers: {
            'X-localization': localStorage.getItem('locale') || 'ar'
        }
    })
}
