import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "login-l" }
const _hoisted_2 = { class: "h1" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { for: "email" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "creat-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AuthContainer = _resolveComponent("AuthContainer")!

  return (_openBlock(), _createBlock(_component_AuthContainer, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('Forget Password')), 1),
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($options.processAction && $options.processAction(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('Email Address')), 1),
            _withDirectives(_createElementVNode("input", {
              type: "email",
              class: "form-control",
              name: "email",
              id: "email",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.email) = $event)),
              placeholder: "example@example.com",
              required: ""
            }, null, 512), [
              [_vModelText, _ctx.data.email]
            ])
          ]),
          _createElementVNode("button", {
            type: "submit",
            class: "btn btn-primary align-self-center",
            disabled: _ctx.loading
          }, _toDisplayString(_ctx.$t('Forget Password')), 9, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_router_link, { to: "login" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Login')), 1)
              ]),
              _: 1
            })
          ])
        ], 32)
      ])
    ]),
    _: 1
  }))
}