import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "login-l" }
const _hoisted_2 = { class: "h1" }
const _hoisted_3 = { for: "password" }
const _hoisted_4 = ["type"]
const _hoisted_5 = {
  key: 2,
  class: "text-danger mt-1 font-weight-bold error_msg"
}
const _hoisted_6 = { for: "password_confirm" }
const _hoisted_7 = ["type"]
const _hoisted_8 = {
  key: 2,
  class: "text-danger mt-1 font-weight-bold error_msg"
}
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 1,
  class: "loading"
}
const _hoisted_12 = { class: "creat-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AuthContainer = _resolveComponent("AuthContainer")!

  return (_openBlock(), _createBlock(_component_AuthContainer, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('reset_password')), 1),
        _createElementVNode("form", {
          onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => ($options.processAction && $options.processAction(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["form-group mb-2", { 'form-group--error': _ctx.v$.data.password.$error && _ctx.data.password.length > 0}])
          }, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Password')), 1),
            _withDirectives(_createElementVNode("input", {
              type: $options.passwordInputType,
              class: "form-control",
              id: "password",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.data.password.$model) = $event)),
              placeholder: "********"
            }, null, 8, _hoisted_4), [
              [_vModelDynamic, _ctx.v$.data.password.$model]
            ]),
            (_ctx.showPassword)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPassword=!_ctx.showPassword)),
                  class: "toggle-password fa fa-fw fa-eye"
                }))
              : (_openBlock(), _createElementBlock("i", {
                  key: 1,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPassword=!_ctx.showPassword)),
                  class: "toggle-password fa fa-fw fa-eye-slash"
                })),
            (_ctx.v$.data.password.$error && _ctx.data.password.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.v$.data.password.$errors[0].$message), 1))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["form-group mb-2", { 'form-group--error': _ctx.v$.data.password_confirmation.$error && _ctx.data.password_confirmation.length > 0 }])
          }, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('confirm_password')), 1),
            _withDirectives(_createElementVNode("input", {
              type: $options.passwordCInputType,
              class: "form-control",
              id: "password_confirm",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$.data.password_confirmation.$model) = $event)),
              placeholder: "********"
            }, null, 8, _hoisted_7), [
              [_vModelDynamic, _ctx.v$.data.password_confirmation.$model]
            ]),
            (_ctx.showPassword)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showPasswordC=!_ctx.showPasswordC)),
                  class: "toggle-password fa fa-fw fa-eye"
                }))
              : (_openBlock(), _createElementBlock("i", {
                  key: 1,
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showPasswordC=!_ctx.showPasswordC)),
                  class: "toggle-password fa fa-fw fa-eye-slash"
                })),
            (_ctx.v$.data.password_confirmation.$error && _ctx.data.password_confirmation.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('password_error')), 1))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("button", {
            type: "submit",
            class: "btn btn-primary align-self-center mt-4",
            disabled: _ctx.loader
          }, [
            (!_ctx.loader)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t('reset_password')), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('loading')), 1))
          ], 8, _hoisted_9),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_router_link, { to: "login" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Login')), 1)
              ]),
              _: 1
            })
          ])
        ], 32)
      ])
    ]),
    _: 1
  }))
}