import {createStore} from 'vuex'
import createPersistedState from "vuex-persistedstate";
import {getCountries} from "@/services/auth";
import {updateCart} from "@/services/cart";

import {getProducts} from '@/services/products'
import {store} from './productsModule/index'
import  {settingsModule} from './settingsModule/index'
export default createStore({
    state: {
        user: {
            loggedIn: false,
            data: null
        },
        countries: null,
        currency: {},
        products: null,
        favoriteProducts: [] as any[],
        cartProducts: [] as any[],
        user_address: null,
        categories: null,
        brands: [],

    },
    getters: {
        user(state) {
            return state.user
        },
        countries(state) {
            return state.countries
        },
        getCurrency(state) {
            return state.currency
        },
        categories(state) {
            return state.categories
        },
        brands(state) {
            return state.brands
        },
        favoriteProducts(state) {
            return state.favoriteProducts || []
        },
        favItemsCount(state) {
            return state.favoriteProducts.length
        },
        cartProducts(state) {
            return state.cartProducts || []
        },
        cartItemsCount(state) {
            let total = 0;
            state.cartProducts.forEach(item => {
                total += (item.quantity)
            })
            return total
        },
        user_address(state) {
            return state.user_address;
        }
    },
    mutations: {
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },
        SET_COUNTRIES(state, data) {
            state.countries = data
        },
        SET_CURRENCY(state, data) {
            state.currency = data
        },
        SET_CATEGORIES(state, data) {
            state.categories = data
        },
        SET_BRANDS(state, data) {
            state.brands = data
        },
        SET_ADDRESS(state, data) {
            state.user_address = data
        },
        SET_FAVORITE(state, data) {
            state.favoriteProducts = data
        },
        UPDATE_FAVORITE: function (state, data) {
            const index = state.favoriteProducts.findIndex(product => product['id'] === data.id)
            if (index !== -1) {
                state.favoriteProducts.splice(index, 1)
            } else {
                state.favoriteProducts.push(data)
            }
        },
        SET_CART(state, data) {
            state.cartProducts = data
        },
        PUSH_PRODUCT_TO_CART(state, product) {
            state.cartProducts.push(product)
        },
        DELETE_FROM_CART(state, product) {
            const index = state.cartProducts.findIndex(item => item.id === product.id)
            if (index !== -1) {
                state.cartProducts.splice(index, 1)
            }
        },
        UPDATE_QUANTITY(state, data) {
            const cart_Item = state.cartProducts.find(item => item.product_id === data.product_id);
            cart_Item.quantity += data.quantity;
        },
        INCREMENT_QUANTITY(state, data) {
            const cart_Item = state.cartProducts.find(item => item.id === data.id);

            cart_Item.quantity++
        },
        DECREMENT_QUANTITY(state, data) {
            const cart_Item = state.cartProducts.find(item => item.id === data.id);
            cart_Item.quantity--
        },
        DROP_ALL_FROM_CART(state) {
            state.cartProducts = [];
        },
        RESET_ALL(state) {
            state.cartProducts = [];
            state.favoriteProducts = [];
            state.products = null;
            state.user_address = null
        },
    },
    actions: {
        resetAll({commit}) {
            commit("RESET_ALL")
        },
        resetCart({commit}) {
            commit("DROP_ALL_FROM_CART")
        },
        fetchUser({commit}, user) {
            commit("SET_LOGGED_IN", user !== null);
            if (user) {
                commit("SET_USER", user);
            } else {
                commit("SET_USER", null);
                commit("SET_LOGGED_IN", false);
            }
        },
        fetchAddress({commit}, data) {
            commit("SET_ADDRESS", data)
        },
        fetchFavourite({commit}, data) {
            commit("SET_FAVORITE", data)
        },
        toggleFav(context, product) {
            context.commit('UPDATE_FAVORITE', product)
        },
        fetchCart({commit}, data) {
            commit("SET_CART", data)
        },

        addProductToCart(context, cartItem) {
            // const cart_Item = context.state.cartProducts.find(item =>  item.product_id === cartItem.product_id);

            // if (!cart_Item) {
            context.commit('PUSH_PRODUCT_TO_CART', cartItem)
            // } else {
            //     context.commit('UPDATE_QUANTITY', cartItem)
            // }
        },
        deleteFromCart(context, data) {
            const cart_item = context.state.cartProducts.find(item => item.id === data);
            if (cart_item) {
                context.commit('DELETE_FROM_CART', cart_item)
            }
        },
        handleIncrement(context, data) {
            const cart_item = context.state.cartProducts.find(item => item.id === data);

            if (cart_item) {
                const reqData = {
                    cart_id: data,
                    product_id: cart_item.product_id,
                    quantity: cart_item.quantity + 1
                }

                context.commit('INCREMENT_QUANTITY', cart_item)
                updateCart(reqData).then(res => console.log(res))
            }
        },
        handleDecrement(context, data) {
            const cart_item = context.state.cartProducts.find(item => item.id === data);


            if (cart_item) {
                const reqData = {
                    cart_id: data,
                    product_id: cart_item.product_id,
                    quantity: cart_item.quantity - 1
                }
                context.commit('DECREMENT_QUANTITY', cart_item)
                updateCart(reqData).then(res => console.log(res))
            }
        },
        changeCurrency(context, data) {
            context.commit('SET_CURRENCY', data)
        },

    },
    modules: {
        productsModule: store,
        settingsModule
    },
    plugins: [createPersistedState()]
})
