import request from "@/helper/request";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getProducts(current_page = 1,country_id){
    return request({
        url: `/api/product`,
        params: {current_page,country_id},
        method:'get'
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function showProduct(product_id) {
    return request({
        url:`/api/product/show?product_id=${product_id}`,
        method: 'get'
    })
}

export default function  brands(){
    return request({
        url: 'api/product/brands',
        method: 'get'
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getFavourites(){
    return request({
        url:'/api/product/favorites',
        method: 'get'
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function toggle_favorite(data){
    console.log(data)
    return request({
        url:'/api/product/toggle_favorite',
        method: 'post',
        data
    })
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function SearchBrand(params){
    return request({
        url: `/api/product`,
        method:'get',
        params
    })
}


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function rateOrder(data) {
    return request(
        {
            url: '/api/product/review',
            method: 'post',
            data
        }
    )
}