import {getStoreSettings} from "@/services/home";
import {useToast} from "vue-toastification";
const toast = useToast();
export const settingsModule = {
    state: {
        settings: [],
        cities: []
    },
    mutations: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        SET_SETTINGS(state,data){
            state.settings = data
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        SET_CITIES(state,data){
            state.cities = data
        }
    },
    getters: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      settings: state => state.settings,
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        cities: state => state.cities
    },
    actions: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        async fetchSettings({commit,getters},lang = '')
        {
            try {
                if(!getters.settings.length || lang) {
                    const result = await getStoreSettings()
                    await commit('SET_SETTINGS',result.data.data.Settings)
                    commit('SET_COUNTRIES', result.data.data.Countries)
                    commit('SET_CITIES', result.data.data.Cities)
                    commit('SET_CATEGORIES', result.data.data.Categories)
                    commit('SET_BRANDS', result.data.data.Brands)
                    if(!localStorage.getItem('country')) {
                        localStorage.setItem('country','true')
                        console.log(localStorage.getItem('country'))
                        if (this.state.user.loggedIn && this.state.countries !== null) {
                            const userCountry = this.state.countries.find(county=>county.id === this.state.user.data.country_id);
                            commit('SET_CURRENCY', userCountry)
                        }else {

                            commit('SET_CURRENCY', this.state.countries[0])

                        }
                    }

                }
            }catch (e){
                toast.error('error_msg')
            }
        }
    }

}