export default {
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "Welcome to the Matress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the Matress"])},
  "Fill in the data and then enjoy the best types of mattresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the data and then enjoy the best types of mattresses"])},
  "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "Forget Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forget Password"])},
  "Create New Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Account"])},
  "Create Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
  "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "Invitation Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation Code"])},
  "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
  "manual_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map manual"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading ..."])},
  "password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords must be identical"])},
  "old_password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password Not Correct"])},
  "password_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Changed Successfully"])},
  "required_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field cannot be empty"])},
  "login_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These credentials do not match our records."])},
  "signup_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or phone number has already been taken."])},
  "follow_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us on"])},
  "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands"])},
  "error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again"])},
  "success_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully saved."])},
  "alert_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this item?"])},
  "Entre Otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre Otp"])},
  "Send code again !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send code again !"])},
  "opt_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Otp Code That Send To Your Email Address"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "code_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The OTP Code Not Correct"])},
  "code_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password Code Has been sent"])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
  "Subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
  "rate_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate the Order"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "direct_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact via Whatsapp"])},
  "no_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد منتجات"])},
  "nav": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "change-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Language"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "all-brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands"])},
    "favourite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favourite"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "terms-of-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])}
  },
  "home": {
    "products-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products Delivery"])},
    "delivery-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery within 24 hours"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "help-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer service and follow up"])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality"])},
    "quality-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Brands"])},
    "payment-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Accepted"])},
    "payment-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KNET , visa, master, cash"])},
    "most-selling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most Selling"])},
    "month-offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers of the month"])},
    "all-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Products"])},
    "get-the-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get the mobile app"])},
    "get-app-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the application and enjoy the many advantages"])},
    "get-app-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And more great offers."])}
  },
  "settings": {
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "general-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Account Settings"])},
    "my-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Orders"])},
    "previous-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Orders"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "my-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points"])},
    "points-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of points you got "])},
    "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["point"])},
    "edit-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
    "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "add-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Address"])}
  },
  "order": {
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Price"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
    "promoCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PromoCode"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "order-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Details"])},
    "product-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
    "product-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name"])},
    "shipping-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Address"])},
    "invoice-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice details"])},
    "receipt-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive request"])},
    "order_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
    "order_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])}
  },
  "product": {
    "add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "similar-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Similar Products"])},
    "added-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added successfully"])},
    "cart-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no products in your cart."])}
  },
  "contact": {
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send us what you want and we will respond to you\nas soon as possible"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
  }
}