import {getProducts} from "@/services/products";

export const store = {
    state: {
        products: [],
        isLoading: true,
    },
    mutations: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        UPDATE_PRODUCT(state, data) {
            state.products = data
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        TOGGLE_IS_LOADING(state, data) {
            state.isLoading = data
        }
    },
    actions: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        fetchProducts({commit}, country_id) {
            getProducts(1, country_id)
                .then(res => {
                    commit('UPDATE_PRODUCT', res.data.Product)
                })
        }
    },
    getters: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        products: state => state.products,

        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        isLoading: state => state.isLoading

    }
}