import request from "@/helper/request";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getCareItems(){
    return request({
        url: '/api/cart',
        method: 'get'
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function addToCart(data){
    return request({
        url: '/api/cart/store',
        method: 'post',
        data
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function updateCart(data){
    return request({
        url:'/api/cart/update',
        method:'post',
        data
    })
}


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function removeItem(product_id){
    return request({
        url: '/api/cart/delete',
        method:'post',
        data: product_id
    })
}

export function checkPromoCode(data){
    return request(
        {
            url: '/api/cart/promocode',
            method:'post',
            data
        }
    )
}