<template>
    <Loading v-if="loading"/>
    <router-view :key="$route.fullPath" v-else/>
</template>

<script>


import store from '@/store'
import Loading from "@/components/Loading";
export default {
    components: {Loading},
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data(){
        return {
            loading: false
        }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    mounted() {
        let lang = localStorage.getItem("locale");
        this.$i18n.locale =  lang || 'ar';
        store.dispatch('fetchSettings')
        document.dir = lang === "en" ? 'ltr' : 'rtl';
        console.log(`Working on '${process.env.VUE_APP_MODE}' mode`)
    },
    watch: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        '$i18n.locale': function() {
            this.loading = true;
            setTimeout(()=>{this.loading = false},700)
        }
    }
}
</script>