import { initializeApp } from "firebase/app";
import { getAuth, signInWithRedirect,FacebookAuthProvider, GoogleAuthProvider ,getRedirectResult} from "firebase/auth";


const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
};

// Initialize Firebase
initializeApp(firebaseConfig);

const auth = getAuth();


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function handleFacebookAuth() {
    const provider = new FacebookAuthProvider();
    signInWithRedirect(auth, provider);
    provider.addScope('email');
    provider.addScope('user_birthday');
    auth.signInWithRedirect(provider).then(res => console.log(res))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function handleGoogleAuth(){
    const googleProvider = new GoogleAuthProvider();
    signInWithRedirect(auth, googleProvider);

    getRedirectResult(auth)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access Google APIs.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;

            console.log(token)
            // The signed-in user info.
            const user = result.user;
            console.log(user)
        }).catch((error) => {
        console.log(error)
        // Handle Errors here.
        //const errorCode = error.code;
        //const errorMessage = error.message;
        // The email of the user's account used.
        //const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(credential)
        // ...
    });

}