import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import ForgetPassword from "@/views/ForgetPassword.vue";
import ForgetPasswordOTP from "@/views/ForgetPasswordOTP.vue";
import ResetPassword from "@/views/ResetPassword.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: {title: 'Home',visitor:true},
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {title: 'Login',visitor:true},
    component: Login,
  },
  {
    path: '/signup',
    name: 'signup',
    meta: {title: 'Signup',visitor:true},
    component: Signup
  },
  {
    path: '/forget_password',
    name: 'forget_password',
    meta: {title: 'Forget Password',visitor:true},
    component: ForgetPassword
  },
  {
    path: '/reset_password_otp',
    name: 'reset_password_otp',
    meta: {title: 'Forget Password',visitor:true},
    component: ForgetPasswordOTP
  },
  {
      path: '/reset_password',
    name: 'reset_password',
    meta: {title: 'Reset Password',visitor:true},
    component: ResetPassword

  },
  {
    path: '/about',
    name: 'About',
    meta: {title: 'About'},
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    meta: {title: 'Privacy Policy'},
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    meta: {title: 'Terms And Conditions'},
    component: () => import(/* webpackChunkName: "TermsAndConditions" */ '../views/TermsAndConditions.vue')
  },
  {
    path: '/support',
    name: 'Support',
    meta: {title: 'Support'},
    component: () => import(/* webpackChunkName: "Support" */ '../views/Support.vue')
  },
  {
    path: '/contact-us',
    name: 'Contact',
    meta: {title: 'Contact Us'},
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact-us.vue')
  },
  {
    path: '/products/:slug',
    name: 'Product',
    meta:{visitor:true},
    component: () => import(/* webpackChunkName: "product" */ '../views/Product/index.vue')
  },
  {
    path: '/favourite',
    name: 'Favourite',
    meta: {title: 'Favourite Products'},
    component: () => import(/* webpackChunkName: "favourite" */ '../views/Favourite.vue')
  },
  {
    path: '/brands',
    name: 'Brands',
    meta: {title: 'Brands',visitor:true},
    component: () => import(/* webpackChunkName: "Brands" */ '../views/BrandsFilter.vue')
  },
  {
    path: '/brand/:id',
    name: 'Brand',
    meta:{visitor:true,title: 'Brands'},
    component: () => import(/* webpackChunkName: "Brands" */ '../views/Brand.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {title: 'Setting'},
    component: () => import(/* webpackChunkName: "Settings" */ '../views/Settings/index.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    meta: {title: 'Cart'},
    component: () => import(/* webpackChunkName: "Cart" */ '../views/Cart/index.vue')
  },
  {
    path: '/order-details/:id',
    name: 'OrderDetails',
    meta: {title: 'Order Details'},
    component: () => import(/* webpackChunkName: "OrderDetails" */ '../views/Cart/OrderDetails.vue')
  },
  {
    // will match everything
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "Not Found" */ '../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title as string
  if (!to.meta.visitor && !localStorage.getItem('access_token')) next({ name: 'login' })
  else next()
})

export default router
