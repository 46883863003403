<template>
    <div class="d-flex justify-content-center overly">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Loading"
}
</script>

<style scoped>
.overly {
    height: 100vh;
    align-items: center;
}
</style>