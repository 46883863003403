import axios from 'axios';
// import store  from '../store/store'

let lang = localStorage.getItem('locale') ? localStorage.getItem('locale')  : 'ar';

const request = axios.create({
    baseURL: process.env.VUE_APP_REMOTE_URL,
    headers: {'X-Requested-With': 'XMLHttpRequest','X-localization': lang},

})

request.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


request.interceptors.request.use(function (config) {
    const token = localStorage.getItem('access_token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';

    return config;
});

export default request;