
// import 'bootstrap/dist/css/bootstrap.css';

  import  'proper';
  import 'bootstrap/dist/js/bootstrap.min.js';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import '@/assets/css/style.css';
  import '@/assets/css/style-en.css';


export default {
  name: 'AuthContainer',

}
