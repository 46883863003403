import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "login-l" }
const _hoisted_2 = { class: "h1" }
const _hoisted_3 = { class: "hello text-center" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "loading"
}
const _hoisted_8 = { class: "creat-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthContainer = _resolveComponent("AuthContainer")!

  return (_openBlock(), _createBlock(_component_AuthContainer, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('Entre Otp')), 1),
        _createElementVNode("form", {
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => ($options.processAction && $options.processAction(...args)), ["prevent"]))
        }, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('otp_text') + ' ' + _ctx.email), 1),
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.otp) = $event)),
              class: "form-control code-control"
            }, null, 512), [
              [_vModelText, _ctx.otp]
            ])
          ]),
          _createElementVNode("button", {
            type: "submit",
            class: "btn btn-primary align-self-center",
            disabled: _ctx.loader
          }, [
            (!_ctx.loader)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('confirm')), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('loading')), 1))
          ], 8, _hoisted_5),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("a", {
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($options.sendCodeAgain && $options.sendCodeAgain(...args)), ["prevent"]))
            }, _toDisplayString(_ctx.$t('Send code again !')), 1)
          ])
        ], 32)
      ])
    ]),
    _: 1
  }))
}