
import AuthContainer from '@/containers/AuthContainer.vue';
import {forgetPassword} from "@/services/auth";

export default {
  name: "ForgetPassword",
  components: {
    AuthContainer,
  },
  data: () => {
    return {
      data: {
        email: "",
      },

      loading: false,
    }
  },
  methods: {
    processAction: function (): void {
        this.loading = true;
        let data = {
            email : this.data.email,
            type: 1
        }
        forgetPassword(data).then(res=> {
            console.log(res);
            this.loading = false;
            this.$router.push({ name: 'reset_password_otp' , params: { email:this.data.email }})
        })
        .catch(e=>{
            this.loading = false;
            console.log(e)
        })

    }
  }


}
