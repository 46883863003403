
import AuthContainer from '@/containers/AuthContainer.vue';
import useValidate from '@vuelidate/core';
import {useToast} from "vue-toastification";
import store from '../store';
import {email, helpers, required} from '@vuelidate/validators'
import {login, socialLogin} from "@/services/auth";
import {handleFacebookAuth} from '@/services/firebase'

export default {
    name: "Login",
    components: {
        AuthContainer,
    },
    data: () => {
        return {
            v$: useValidate(),
            toast: useToast(),
            login: {
                email: "",
                password: ""
            },
            showPassword: false,
            loading: false,
            hasError: false,
            errorMessage: '',
            error_msg: "",

        }
    },
    mounted() {
        // eslint-disable-next-line no-undef
        // window.gapi.signin2.render('google_auth', {
        //     scope: 'profile email',
        //     fetch_basic_profile:true,
        //     onsuccess: this.onSignIn,
        //     onfailure: this.onfailure,
        //
        // })
    },
    computed: {
        passwordInputType: function (): string {
            return (this as any).showPassword ? "text" : "password";
        }
    },
    validations() {
        return {
            login: {
                email: {
                    required: helpers.withMessage(this.$t('required_msg'), required),
                    email
                },
                password: {
                    required: helpers.withMessage(this.$t('required_msg'), required)
                },
            },
        }
    },
    methods: {
        handleLogin() {
            this.loading = true;
            this.v$.$validate() // checks all inputs
            if (this.v$.$error) { // if ANY fail validation
                this.loading = false;
                // this.toast.error(this.$t('login_error'), {timeout: 4000, closeButton: false,})
                return false
            }
            try {
                login(this.login).then(response => {
                    this.loading = false;
                    if (response.data.status.status === 'fail') {
                        this.toast.error(this.$t('login_error'), {timeout: 4000, closeButton: false,})
                    } else {
                        store.dispatch("fetchUser", response.data.User);
                        let token = response.data.User.access_token;
                        localStorage.setItem('access_token', token)
                        this.$router.push('/');
                    }
                }).catch(() => {
                    this.loading = false;
                })
            } catch (e) {
                this.loading = false
                console.log(e)
            }

        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        loginWithFacebook() {
            handleFacebookAuth()
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        loginWithGoogle() {
            handleFacebookAuth()
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        onSignIn(googleUser) {
            let profile = googleUser.getBasicProfile();
            let token = googleUser.getAuthResponse().id_token;
            let name = profile.getName();
            let email = profile.getEmail();
            let image = profile.getImageUrl();
            localStorage.setItem('access_token', token)

            if(googleUser.isSignedIn()) {
                let userData = {
                    id: token,
                    name,
                    email,
                    country_id:null,
                    city_id : null,
                    image,
                    address: null,
                    lat: null,
                    lng: null,
                    points: 0,
                    access_token:token,
                    token_type : "Bearer"
                }

                store.dispatch("fetchUser", userData);

                this.$router.push('/');
                socialLogin({
                    provider: 'google',
                    token,
                    device_type: 'web'
                }).then(res=>console.log(res))
                    .catch(e=>console.log(e))

                console.log(userData);
            }
          // This is null if the 'email' scope is not present.
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        // onfailure(e) {
        //     console.log(e)
        //     this.toast.error(this.$t('error_msg'), {timeout: 4000, closeButton: false,})
        // },

    }


}

