
import AuthContainer from '@/containers/AuthContainer.vue';
import {helpers, minLength, required, sameAs} from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import {resetPassword} from "@/services/auth";
import {useToast} from "vue-toastification";
import store from "@/store";

export default {
    name: "ResetPassword",
    components: {
        AuthContainer,
    },
    data: () => {
        return {
            v$: useValidate(),
            toast: useToast(),
            data: {
                password: "",
                code: "",
                email:"",
                password_confirmation: "",
            },

            showPasswordC: false,
            showPassword: false,
            loader: false,
            hasError: false,
            errorMessage: '',
            error_msg: "",

        }
    },
    mounted() {
        if (this.$route.params.code) {
            this.data.code = this.$route.params.code
            this.data.email = this.$route.params.email
        } else {
            this.$router.push('/login')
        }
    },
    validations() {
        let required_msg = this.$i18n.locale === 'ar' ? 'هذا الحقل مطلوب' : 'This field cannot be empty'

        return {
            data: {
                password: {
                    required: helpers.withMessage(required_msg, required),
                    min: minLength(6),
                },
                password_confirmation: {
                    required: helpers.withMessage(required_msg, required),
                    sameAsPassword: sameAs(this.data.password)
                },
            }
        }
    },
    computed: {
        passwordInputType: function (): string {
            return (this as any).showPassword ? "text" : "password";

        },
        passwordCInputType: function (): string {
            return (this as any).showPasswordC ? "text" : "password";
        }
    },

    methods: {
        processAction: function (): void {
            this.loader = true
            resetPassword(this.data).then(res=> {
                this.loader = false
                if(res.data.status.status === 'fail'){
                    this.toast.error('Something went wrong, try again!', {timeout: 4000, closeButton: false,})
                }else {
                    console.log(res)
                    store.dispatch("fetchUser", res.data.User);
                    let token = res.data.User.access_token;
                    localStorage.setItem('access_token', token)
                    this.$router.push('/');
                }
            }).catch(e=>{
                this.loader = false
                console.log(e)
            })

        }
    }


}
